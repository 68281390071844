import Layout from "../components/Layout";
import {Center,Space, Text} from "@mantine/core"
import EmailSignupBanner from "../components/EmailSignupBanner";
import { useEffect } from "react";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";

export default function Custom404() {

    const router = useRouter();
    
    useEffect(()=>
    {
        Sentry.captureMessage("404: Page not found", {extra: {page: router.asPath, referrer:document.referrer}});
    }
    ,
    []);

    return <Layout> 
    <h1>Oops!</h1>

   


<Center>
    <Text>I&rsquo;m sorry but we can&rsquo;t find the page you&rsquo;re looking for. Please try using the links on the top or bottom of this page to explore the site.</Text>
    </Center>




    <Space h ='xl'/>

    <EmailSignupBanner/>
    </Layout>
  }